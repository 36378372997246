import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';

@Directive({
  selector: '[appGooglePlacesAutocomplete]',
  standalone: true
})
export class GooglePlacesAutocompleteDirective implements AfterViewInit, OnDestroy{

  @Input('options') options: google.maps.places.AutocompleteOptions;
  @Output() onAddressChange: EventEmitter<google.maps.places.PlaceResult> = new EventEmitter();
  private autocomplete: google.maps.places.Autocomplete;
  private eventListener: google.maps.MapsEventListener = null;
  private eventKeyListener: any = null;
  public place: google.maps.places.PlaceResult;
  constructor(private element: ElementRef, private ngZone: NgZone) {
   }

  ngAfterViewInit(): void {
    this.initialize();
  }
  ngOnDestroy(): void {
    this.eventListener?.remove();
    this.element?.nativeElement?.removeEventListener('keydown', this.handleKeyEvent);    
    this.eventListener = null;
    this.eventKeyListener = null;
  }


  private isGoogleLibExists(): boolean {
    return !(!google || !google.maps || !google.maps.places);
  }

  private initialize(): void {
    if (!this.isGoogleLibExists()){
      throw new Error("Google maps library can not be found");
    }
   
    this.autocomplete = new google.maps.places.Autocomplete(this.element.nativeElement, this.options);

    if (!this.autocomplete){
      throw new Error("Autocomplete is not initialized");
    }

    if(!this.eventKeyListener)
      this.eventKeyListener = this.element.nativeElement.addEventListener('keydown', this.handleKeyEvent, true);

    if (!this.eventListener) 
        this.eventListener = this.autocomplete.addListener('place_changed', this.handleChangeEvent);
    
  }

  private handleKeyEvent(event: KeyboardEvent){
    if(!event.key) return;
    let key = event.key.toLowerCase();
    if (key == 'enter' && event.target === this.element.nativeElement) {
        event.preventDefault();
        event.stopPropagation();
    }
  }

  private handleChangeEvent(event:google.maps.MapsEventListener): void {
    //this.ngZone.run(() => {
        this.place = this.autocomplete?.getPlace();
        if (this.place) {
            this.onAddressChange.emit(this.place);
        }
   // });
  }

}
